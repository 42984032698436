var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticClass:"shopList"},[_c('el-header',{staticClass:"box add bb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',[_vm._v("商品管理")]),_c('el-breadcrumb-item',[_vm._v("商品信息列表")]),_c('el-breadcrumb-item',[_vm._v("批号详情")])],1),_c('div',[_c('el-button',{staticClass:"back_btn",attrs:{"size":"medium","plain":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回 ")])],1)],1),_c('el-main',{staticClass:"p15 pt15"},[_c('el-form',{ref:"form",staticStyle:{"text-align":"left"},attrs:{"status-icon":"","model":_vm.form,"label-width":"100px","size":"medium"}},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"批次号"}},[_vm._v(" "+_vm._s(_vm.form.batch_number)+" ")])],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"商品名称"}},[_vm._v(" "+_vm._s(_vm.form.name))])],1),_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"label":"商品编码"}},[_vm._v(" "+_vm._s(_vm.form.gtin))])],1)],1),_c('el-divider',[_c('el-tag',[_vm._v("该批次相关资质证照")])],1),_c('span',{staticStyle:{"color":"silver","font-size":"14px"}},[_vm._v("备注：只能上传图片大小小于4M,且非图片类型的文件仅能在e食安监上展示")]),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"营业执照(生产商)"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList0,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 0);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 0);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"营业执照(生产商)(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 0);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 0);
                  },"data":_vm.uploadData,"multiple":"","on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","limit":3,"file-list":_vm.pdfFileList0}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"食品生产许可证(sc)"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList1,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 1);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 1);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"食品生产许可证(sc)(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 1);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 1);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-preview":_vm.handleViewFile,"on-change":_vm.checkFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList1}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"第三方检测报告"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList2,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 2);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 2);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"第三方检测报告(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 2);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 2);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList2}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"批次出厂检测报告"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList3,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 3);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 3);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"批次出厂检测报告(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 3);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 3);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList3}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"入境货物检验检疫证明"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList4,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, _vm.fileLis, 4);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 4);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"入境货物检验检疫证明(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 4);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 4);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList4}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"食品安全快检"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList5,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 5);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 5);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"食品安全快检(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 5);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 5);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList5}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"核酸检测报告"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList6,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 6);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 6);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"核酸检测报告(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 6);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 6);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList6}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"消毒证明"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList7,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 7);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 7);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"消毒证明(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 7);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 7);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList7}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"出仓证明"}},[_c('el-row',[_c('el-upload',{attrs:{"action":"#","list-type":"picture-card","file-list":_vm.fileList8,"on-preview":_vm.handleViewPic,"on-remove":function (file, fileList) {
                    _vm.handleRemove(file, fileList, 8);
                  },"http-request":function (params) {
                    _vm.uploadSectionFile(params, 8);
                  }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-form-item',{attrs:{"label-width":"150","label":"出仓证明(Pdf/Word/Excel版)"}},[_c('el-row',[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://cll20.oss-cn-chengdu.aliyuncs.com","before-upload":_vm.beforeUpload,"on-remove":function (res, file) {
                    _vm.handleRemovePdf(res, file, 8);
                  },"on-success":function (res, file) {
                    _vm.handleSuccess(res, file, 8);
                  },"data":_vm.uploadData,"multiple":"","limit":3,"on-change":_vm.checkFile,"on-preview":_vm.handleViewFile,"accept":".pdf,.doc,.xlsx,.xls","file-list":_vm.pdfFileList8}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)],1)],1),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":24}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("提交")])],1)],1)],1),(_vm.showViewer)?_c('el-image-viewer',{staticStyle:{"z-index":"9999"},attrs:{"on-close":_vm.closeViewer,"url-list":_vm.url}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }