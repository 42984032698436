<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>商品信息列表</el-breadcrumb-item>
        <el-breadcrumb-item>批号详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()"
          >返回
        </el-button>
      </div>
    </el-header>
    <el-main class="p15 pt15">
      <el-form
        status-icon
        ref="form"
        :model="form"
        label-width="100px"
        size="medium"
        style="text-align: left"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="批次号">
              {{ form.batch_number }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品名称"> {{ form.name }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商品编码"> {{ form.gtin }}</el-form-item>
          </el-col>
        </el-row>
        <el-divider>
          <el-tag>该批次相关资质证照</el-tag>
        </el-divider>
        <span style="color: silver; font-size: 14px"
          >备注：只能上传图片大小小于4M,且非图片类型的文件仅能在e食安监上展示</span
        >
        <el-row>
          <el-col :span="24">
            <el-form-item label="营业执照(生产商)">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList0"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 0);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 0);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item
              label-width="150"
              label="营业执照(生产商)(Pdf/Word/Excel版)"
            >
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 0);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 0);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :limit="3"
                  :file-list="pdfFileList0"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="食品生产许可证(sc)">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList1"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 1);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 1);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item
              label-width="150"
              label="食品生产许可证(sc)(Pdf/Word/Excel版)"
            >
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 1);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 1);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-preview="handleViewFile"
                  :on-change="checkFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList1"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="第三方检测报告">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList2"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 2);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 2);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item
              label-width="150"
              label="第三方检测报告(Pdf/Word/Excel版)"
            >
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 2);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 2);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList2"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="批次出厂检测报告">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList3"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 3);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 3);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item
              label-width="150"
              label="批次出厂检测报告(Pdf/Word/Excel版)"
            >
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 3);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 3);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList3"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="入境货物检验检疫证明">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList4"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileLis, 4);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 4);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item
              label-width="150"
              label="入境货物检验检疫证明(Pdf/Word/Excel版)"
            >
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 4);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 4);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList4"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="食品安全快检">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList5"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 5);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 5);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item
              label-width="150"
              label="食品安全快检(Pdf/Word/Excel版)"
            >
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 5);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 5);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList5"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="核酸检测报告">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList6"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 6);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 6);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item
              label-width="150"
              label="核酸检测报告(Pdf/Word/Excel版)"
            >
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 6);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 6);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList6"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="消毒证明">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList7"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 7);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 7);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item label-width="150" label="消毒证明(Pdf/Word/Excel版)">
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 7);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 7);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList7"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="出仓证明">
              <el-row>
                <el-upload
                  action="#"
                  list-type="picture-card"
                  :file-list="fileList8"
                  :on-preview="handleViewPic"
                  :on-remove="
                    (file, fileList) => {
                      handleRemove(file, fileList, 8);
                    }
                  "
                  :http-request="
                    (params) => {
                      uploadSectionFile(params, 8);
                    }
                  "
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-row>
            </el-form-item>
            <el-form-item label-width="150" label="出仓证明(Pdf/Word/Excel版)">
              <el-row>
                <el-upload
                  class="upload-demo"
                  action="https://cll20.oss-cn-chengdu.aliyuncs.com"
                  :before-upload="beforeUpload"
                  :on-remove="
                    (res, file) => {
                      handleRemovePdf(res, file, 8);
                    }
                  "
                  :on-success="
                    (res, file) => {
                      handleSuccess(res, file, 8);
                    }
                  "
                  :data="uploadData"
                  multiple
                  :limit="3"
                  :on-change="checkFile"
                  :on-preview="handleViewFile"
                  accept=".pdf,.doc,.xlsx,.xls"
                  :file-list="pdfFileList8"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="text-align: center">
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-col>
        </el-row>
      </el-form>

      <el-image-viewer
        style="z-index: 9999"
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="url"
      />
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import { local, session } from "@/util/util";
import { mapState } from "vuex";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      form: {
        name: "",
        gtin: "",
        batch_number: "",
        company_id: "",
        img_list0: [],
        img_list1: [],
        img_list2: [],
        img_list3: [],
        img_list4: [],
        img_list5: [],
        img_list6: [],
        img_list7: [],
        img_list8: [],
        pdf_list0: [],
        pdf_list1: [],
        pdf_list2: [],
        pdf_list3: [],
        pdf_list4: [],
        pdf_list5: [],
        pdf_list6: [],
        pdf_list7: [],
        pdf_list8: [],
      },
      fileList0: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      fileList6: [],
      fileList7: [],
      fileList8: [],
      pdfFileList0: [],
      pdfFileList1: [],
      pdfFileList2: [],
      pdfFileList3: [],
      pdfFileList4: [],
      pdfFileList5: [],
      pdfFileList6: [],
      pdfFileList7: [],
      pdfFileList8: [],
      url: "",
      uploadData: {
        //提交到OSS的参数
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
      },
      showViewer: false,
      isUpload: true,
    };
  },
  filters: {},
  computed: {},
  mounted() {
    const { gtin, name, batch_number } = this.$route.query;
    this.form.gtin = gtin;
    this.form.name = name;
    this.form.batch_number = batch_number;
    this.form.company_id = local.get("company_id");
    this.init({ gtin, batch_number });
  },
  methods: {
    init(params) {
      axios.get("/pc/batch/qualification", params).then((v) => {
        if (v.data.qualification.length > 0) {
          this.generateData(v.data.qualification, 1);
        }
      });
    },
    onSubmit() {
      this.generateData([], 2);
      axios.post("/pc/batch/addQual", this.form).then((v) => {
        this.$notify({
          title: "成功",
          message: "提交成功",
          type: "success",
        });
        this.$router.back();
      });
    },
    handleViewFile(file) {
      window.open(file.url);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("goodsManage/setNewPage", this.page);
      this.init(this.page);
    },
    batchCurrentChange(page) {
      this.batchCurrentPage = page;
      this.batchPage.pager_offset = String((page - 1) * 10);
      this.initBatch(this.batchPage);
    },
    //编辑
    handleBatch(row) {
      this.dialogTableVisible = true;
    },
    handleDetail(row) {},
    uploadSectionFile(params, type) {
      //console.log("type: ", type);
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", local.get("company_id"));
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios
        .uploadFile("/pc/file/common", fileFormData)
        .then((resp) => {
          //console.log("resp: ", resp);
          if (resp.code != 200) {
            this.$message.error("图片上传失败，请重新上传");
          } else {
            switch (type) {
              case 0:
                this.fileList0.push({ url: resp.url });
                break;
              case 1:
                this.fileList1.push({ url: resp.url });
                break;
              case 2:
                this.fileList2.push({ url: resp.url });
                break;
              case 3:
                this.fileList3.push({ url: resp.url });
                break;
              case 4:
                this.fileList4.push({ url: resp.url });
                break;
              case 5:
                this.fileList5.push({ url: resp.url });
                break;
              case 6:
                this.fileList6.push({ url: resp.url });
                break;
              case 7:
                this.fileList7.push({ url: resp.url });
                break;
              case 8:
                this.fileList8.push({ url: resp.url });
                break;
            }
          }
        })
        .catch((e) => {
          this.$message.error("图片上传失败，请重新上传");
        })
        .finally(() => {});
    },
    handleRemove(file, fileList, type) {
      switch (type) {
        case 0:
          this.fileList0.splice(
            this.fileList0.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 1:
          this.fileList1.splice(
            this.fileList1.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 2:
          this.fileList2.splice(
            this.fileList2.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 3:
          this.fileList3.splice(
            this.fileList3.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 4:
          this.fileList4.splice(
            this.fileList4.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 5:
          this.fileList5.splice(
            this.fileList5.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 6:
          this.fileList6.splice(
            this.fileList6.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 7:
          this.fileList7.splice(
            this.fileList7.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 8:
          this.fileList8.splice(
            this.fileList8.findIndex((x) => x.url === file.url),
            1
          );
          break;
      }
    },
    handleRemovePdf(file, fileList, type) {
      switch (type) {
        case 0:
          this.pdfFileList0.splice(
            this.pdfFileList0.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 1:
          this.pdfFileList1.splice(
            this.pdfFileList1.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 2:
          this.pdfFileList2.splice(
            this.pdfFileList2.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 3:
          this.pdfFileList3.splice(
            this.pdfFileList3.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 4:
          this.pdfFileList4.splice(
            this.pdfFileList4.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 5:
          this.pdfFileList5.splice(
            this.pdfFileList5.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 6:
          this.pdfFileList6.splice(
            this.pdfFileList6.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 7:
          this.pdfFileList7.splice(
            this.pdfFileList7.findIndex((x) => x.url === file.url),
            1
          );
          break;
        case 8:
          this.pdfFileList8.splice(
            this.pdfFileList8.findIndex((x) => x.url === file.url),
            1
          );
          break;
      }
    },
    checkFile(file, fileList) {
      if (
        file.name.lastIndexOf(".doc") == -1 &&
        file.name.lastIndexOf(".pdf") == -1 &&
        file.name.lastIndexOf(".xlsx") == -1 &&
        file.name.lastIndexOf(".xls") == -1
      ) {
        this.$message.error("只能上传pdf、word、excel文件");
        this.isUpload = false;
        this.uploadData = {};
      } else {
        this.isUpload = true;
      }
    },
    async beforeUpload(file) {
      if (this.isUpload) {
        await axios.get("/pc/file/sign").then((response) => {
          //设置相关的参数
          var resultObj = response.data;
          this.uploadData.policy = resultObj.policy;
          this.uploadData.signature = resultObj.signature;
          this.uploadData.ossaccessKeyId = resultObj.accessid;
          //上传的文件名，使用UUID处理一下
          this.uploadData.key = resultObj.key + "." + file.name.split(".")[1];
          this.uploadData.host = resultObj.host;
        });
      }
    },
    handleSuccess(res, file, type) {
      //console.debug(file);
      //上传的完整的文件地址
      var urlPath = this.uploadData.host + this.uploadData.key;
      switch (type) {
        case 0:
          this.pdfFileList0.push({ url: urlPath, name: file.name });
          break;
        case 1:
          this.pdfFileList1.push({ url: urlPath, name: file.name });
          break;
        case 2:
          this.pdfFileList2.push({ url: urlPath, name: file.name });
          break;
        case 3:
          this.pdfFileList3.push({ url: urlPath, name: file.name });
          break;
        case 4:
          this.pdfFileList4.push({ url: urlPath, name: file.name });
          break;
        case 5:
          this.pdfFileList5.push({ url: urlPath, name: file.name });
          break;
        case 6:
          this.pdfFileList6.push({ url: urlPath, name: file.name });
          break;
        case 7:
          this.pdfFileList7.push({ url: urlPath, name: file.name });
          break;
        case 8:
          this.pdfFileList8.push({ url: urlPath, name: file.name });
          break;
      }
      this.$message({
        message: "上传成功，图片地址：" + urlPath,
        type: "success",
      });
    },
    handleViewPic(src) {
      this.url = [src.url];
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    generateData(data, type) {
      switch (type) {
        case 1:
          data.forEach((item) => {
            //console.log("item: ", item);
            if (item.type_value == 0) {
              if (item.file) {
                this.pdfFileList0.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList0.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 1) {
              if (item.file) {
                this.pdfFileList1.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList1.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 2) {
              if (item.file) {
                this.pdfFileList2.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList2.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 3) {
              if (item.file) {
                this.pdfFileList3.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList3.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 4) {
              if (item.file) {
                this.pdfFileList4.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList4.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 5) {
              if (item.file) {
                this.pdfFileList5.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList5.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 6) {
              if (item.file) {
                this.pdfFileList6.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList6.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 7) {
              if (item.file) {
                this.pdfFileList7.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList7.push({ url: item.pic_url });
              }
            }
            if (item.type_value == 8) {
              if (item.file) {
                this.pdfFileList8.push({
                  url: item.pic_url,
                  name: item.file_name,
                });
              } else {
                this.fileList8.push({ url: item.pic_url });
              }
            }
          });
          break;
        case 2:
          this.form.img_list0 = this.fileList0.map((item) => item.url);
          this.form.img_list1 = this.fileList1.map((item) => item.url);
          this.form.img_list2 = this.fileList2.map((item) => item.url);
          this.form.img_list3 = this.fileList3.map((item) => item.url);
          this.form.img_list4 = this.fileList4.map((item) => item.url);
          this.form.img_list5 = this.fileList5.map((item) => item.url);
          this.form.img_list6 = this.fileList6.map((item) => item.url);
          this.form.img_list7 = this.fileList7.map((item) => item.url);
          this.form.img_list8 = this.fileList8.map((item) => item.url);
          this.form.pdf_list0 = this.pdfFileList0;
          this.form.pdf_list1 = this.pdfFileList1;
          this.form.pdf_list2 = this.pdfFileList2;
          this.form.pdf_list3 = this.pdfFileList3;
          this.form.pdf_list4 = this.pdfFileList4;
          this.form.pdf_list5 = this.pdfFileList5;
          this.form.pdf_list6 = this.pdfFileList6;
          this.form.pdf_list7 = this.pdfFileList7;
          this.form.pdf_list8 = this.pdfFileList8;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;

  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
